import { defineStore } from "pinia";
import { getDateRangeThisMonthFormatted } from "@/utils/helpers";
import { Paginate } from "@/utils/paginate";
import { currencyDividedValueFormatted } from "@/utils/currencyFormat";
import { InflencuerDataItem } from "@/utils/types";
import { useUserStore } from "@/store/UserStore";
import axios from "axios";
import { formatNumber } from "@/utils/helpFunctions";

export const useInfluencersStore = defineStore("InfluencersStore", {
  state: () => ({
    data: {},
    dateRange: getDateRangeThisMonthFormatted(),
    loading: false,
    pagination: { ...Paginate.defaultState },
    sort: "desc",
    format: "",
    url: "agency/influencers",
    orderableItems: {
      Earning: "user_earnings",
      Clicks: "clicks",
      "Agency Earning": "agency_earnings",
    },
    activeItem: "Earning",
    username: "",
  }),

  getters: {
    currentData: ({ data, pagination: { current } }: any) =>
      data[current] || [],
  },

  actions: {
    async fetchInfluencers({
      start,
      end,
      page,
    }: {
      start?: string;
      end?: string;
      page?: number;
    } = {}) {
      this.loading = true;
      const userStore = useUserStore();
      if (userStore.user.clients?.length === 1) {
        await userStore.setSelectedAgency(userStore.user.clients[0].id);
      }
      const orderableItems = this.orderableItems;
      type ObjectKey = keyof typeof orderableItems;
      const orderBy = orderableItems[this.activeItem as ObjectKey];
      const params = {
        from: start || this.dateRange.start,
        to: end || this.dateRange.end,
        page,
        sort: this.sort,
        orderBy,
        format: this.format,
        username: this.username,
      };
      const agencyId = userStore.selectedAgency.id;
      const url = `${this.url}/${agencyId}`;
      try {
        if (this.format) {
          return await this.exportInfluncersData(agencyId, params);
        }
        const { pagination } = this;
        const response = await Paginate.setCurrentPage({
          pagination,
          page,
          context: this,
          url,
          params,
        });

        if (response && response.data) {
          const { current_page, data } = response.data;
          let value = { [current_page]: normalizeInfluencerData(data) };
          if (current_page !== 1) {
            value = { ...this.data, ...value };
          }
          this.data = value;
        }
      } catch (e) {
        console.error("Couldn't fetch dashboard ", e);
      } finally {
        this.loading = false;
      }
    },
    async exportInfluncersData(agencyId: number, params: Record<string, any>) {
      const userStore = useUserStore();
      const { access_token } = userStore.user;
      const url = `${this.url}/${agencyId}`;
      const { data } = await axios.get(url, {
        params,
        headers: {
          Authorization: access_token,
        },
        responseType: "blob",
      });
      const blob = new Blob([data], {
        type: "application/vnd.ms-excel",
      });
      const link = document.createElement("a");
      link.href = URL.createObjectURL(blob);
      link.download = "influencer-stats.xlsx";
      link.click();
      URL.revokeObjectURL(link.href);
    },
  },
});

const normalizeInfluencerData = (
  data: InflencuerDataItem[]
): InflencuerDataItem[] =>
  data.map((item: InflencuerDataItem) => ({
    ...item,
    ...(item.user_earnings !== null && {
      userEarnings: currencyDividedValueFormatted(item.user_earnings),
    }),
    ...(item.agency_earnings !== null && {
      agencyEarnings: currencyDividedValueFormatted(item.agency_earnings),
    }),
    ...(item.clicks !== null && {
      clicks: formatNumber(item.clicks),
    }),
  }));
