<template>
  <line-chart v-bind="lineChartData" />
</template>

<script>
import { defineComponent } from "vue";
import { LineChart } from "vue-chart-3";
import { Chart, registerables } from "chart.js";
Chart.register(...registerables);

export default defineComponent({
  components: { LineChart },
  props: {
    chartData: {
      type: Object,
      required: true,
    },
    chartLabelPosition: {
      type: String,
      required: false,
      default: "bottom",
    },
    chartTension: {
      type: Number,
      required: false,
      default: 0.3,
    },
  },
  setup(props) {
    // eslint-disable-next-line vue/no-setup-props-destructure
    const { chartData, chartLabelPosition, chartTension } = props;
    const lineChartData = {
      chartData,
      options: {
        responsive: true,
        maintainAspectRatio: false,
        plugins: {
          tooltip: {
            titleColor: "#030319",
            bodyColor: "#030319",
            titleFont: {
              size: 16,
            },
            bodyFont: {
              size: 16,
            },
            backgroundColor: "#ffffff",
          },
          callbacks: {
            labelTextColor: () => "#030319",
          },
          legend: {
            position: chartLabelPosition,
          },
        },
        scales: {
          y: {
            stacked: false,
            min: 0,
          },
        },
        tension: chartTension,
      },
    };
    return {
      lineChartData,
    };
  },
});
</script>
