import {
  format,
  subYears,
  subMonths,
  subDays,
  startOfYear,
  startOfMonth,
  endOfMonth,
  getDate,
  differenceInDays,
  setDate,
  isSameYear,
  isSameMonth,
} from "date-fns";
import { DateRangeFormattedModel, DateRangeModel } from "@/utils/types";

const fixedEncodeURIComponent = (str: string) =>
  encodeURIComponent(str).replace(
    /[!'()*]/g,
    (c) => `%${c.charCodeAt(0).toString(16)}`
  );

// eslint-disable-next-line
const buildQuery = (params: any): string => {
  const query: string = Object.keys(params)
    .map((key) => {
      const paramValue = params[key];
      if (
        paramValue !== undefined &&
        paramValue !== null &&
        paramValue !== ""
      ) {
        return typeof paramValue === "object"
          ? buildQuery(paramValue)
          : `${key}=${fixedEncodeURIComponent(paramValue)}`;
      }
    })
    .filter((p) => !!p)
    .join("&");
  return query ? `?${query}` : query;
};

const getDateRangeThisMonth = (): DateRangeModel => ({
  start: startOfMonth(new Date()),
  end: new Date(),
});

const getDateRangeThisMonthFormatted = (): DateRangeFormattedModel => {
  const { start, end } = getDateRangeThisMonth();
  return {
    start: format(start, "yyyy-MM-dd"),
    end: format(end, "yyyy-MM-dd"),
  };
};

const getPrevPeriodFormatted = (
  dateRange: DateRangeFormattedModel | null,
  shortcut: string
): string => {
  let startPrev = new Date();
  let endPrev = startOfMonth(new Date());
  if (shortcut && shortcutKeysMap[shortcut]) {
    const { start, end } = shortcutKeysMap[shortcut]();
    startPrev = new Date(start);
    endPrev = new Date(end);
  } else if (dateRange) {
    const { start, end } = dateRange;
    const startDate = new Date(start);
    const endDate = new Date(end);
    const diffInDays = differenceInDays(endDate, startDate);
    startPrev = subDays(startDate, diffInDays);
    endPrev = subDays(endDate, diffInDays);
  }
  const startDateFormatted = format(
    startPrev,
    `MMM d${isSameYear(startPrev, endPrev) ? "" : ", yyyy"}`
  );
  const endDateFormatted = format(
    endPrev,
    `${isSameMonth(startPrev, endPrev) ? "" : "MMM "}d, yyyy`
  );
  return `${startDateFormatted} - ${endDateFormatted}`;
};

const dateFormat = "yyyy-MM-dd";

const shortcutKeysMap: any = {
  thisMonth: () => {
    const date = subMonths(new Date(), 1);
    const start = format(startOfMonth(date), dateFormat);
    const today = getDate(new Date());
    const lastDayPrevMonth = getDate(endOfMonth(date));
    const endDate = today <= lastDayPrevMonth ? today : lastDayPrevMonth;
    const end = format(setDate(date, endDate), dateFormat);
    return { start, end };
  },
  lastMonth: () => {
    const date = subMonths(new Date(), 2);
    const start = format(startOfMonth(date), dateFormat);
    const lastDayLastMonth = getDate(endOfMonth(subMonths(new Date(), 1)));
    const lastDayPrevLastMonth = getDate(endOfMonth(date));
    const endDate =
      lastDayLastMonth <= lastDayPrevLastMonth
        ? lastDayLastMonth
        : lastDayPrevLastMonth;
    const end = format(setDate(date, endDate), dateFormat);
    return { start, end };
  },
  thisYear: () => {
    const date = subYears(new Date(), 1);
    const start = format(startOfYear(date), dateFormat);
    const end = format(date, dateFormat);
    return { start, end };
  },
};

const getPreviousPeriod = ({
  shortcut,
  dateRange,
}: {
  shortcut: string | undefined;
  dateRange: any;
}): DateRangeFormattedModel => {
  if (shortcut && shortcutKeysMap[shortcut]) {
    return shortcutKeysMap[shortcut]();
  } else {
    const start = new Date(dateRange.start);
    const end = new Date(dateRange.end);
    const diffInDays = differenceInDays(end, start);
    return {
      start: format(subDays(start, diffInDays), dateFormat),
      end: format(subDays(end, diffInDays), dateFormat),
    };
  }
};

const getDaysArray = (
  start: string | Date,
  end: string | Date
): Array<string | Date | number> => {
  const arr = [];
  const dt = new Date(start);
  for (; dt <= new Date(end); dt.setDate(dt.getDate() + 1)) {
    arr.push(format(new Date(dt), "yyyy-MM-dd"));
  }
  return arr;
};

export {
  buildQuery,
  getDateRangeThisMonth,
  getDateRangeThisMonthFormatted,
  getPreviousPeriod,
  getPrevPeriodFormatted,
  getDaysArray,
};
