import { get } from "lodash";

const fallbackLang = "en";

const numberFormatter = new Intl.NumberFormat(
  navigator.language || fallbackLang
);

const formatNumber = (number: number | string): string =>
  numberFormatter.format(Number(number)).replaceAll(",", " ");

const formatFractionAsPercentage = (value: number | string) =>
  formatNumber(Number(value) * 100) + "%";

const getErrorMessage = (e: Error, defaultMessage: string): string => {
  const message = get(e, "response.data.message", "");
  const error = get(e, "response.data.error", "");
  return message || error || defaultMessage;
};

export { formatNumber, getErrorMessage, formatFractionAsPercentage };
