import { createRouter, createWebHistory, RouteRecordRaw } from "vue-router";
import Login from "@/views/Login.vue";
import Register from "@/views/Register.vue";
import Dashboard from "@/views/Dashboard.vue";
import Influencers from "@/views/Influencers.vue";
import Influencer from "@/views/Influencer.vue";
import { useUserStore } from "@/store/UserStore";

const routes: Array<RouteRecordRaw> = [
  {
    path: "/",
    name: "Base",
    redirect: {
      name: "Login",
    },
  },
  {
    path: "/login",
    name: "Login",
    component: Login,
  },
  {
    path: "/register",
    name: "Register",
    component: Register,
  },
  {
    path: "/reset-password",
    name: "ResetPassword",
    component: Login,
  },
  {
    path: "/dashboard",
    name: "Dashboard",
    component: Dashboard,
  },
  {
    path: "/influencers",
    name: "Influencers",
    component: Influencers,
  },
  {
    path: `/influencers/:id`,
    name: "Influencer",
    component: Influencer,
  },
];

const router = createRouter({
  history: createWebHistory(),
  routes,
});

router.beforeEach((to) => {
  const { name } = to;
  const userStore = useUserStore();

  if ((name === "Login" || name === "Register") && userStore.isLogged) {
    // Redirect to Dashboard if the user is already logged in
    return { name: "Dashboard" };
  }

  if (
    name !== "Login" &&
    name !== "ResetPassword" &&
    name !== "Register" &&
    !userStore.isLogged
  ) {
    return { name: "Login" };
  }

  return true;
});

export default router;
