import { useUserStore } from "@/store/UserStore";
const fallbackLang = "en";
const fallbackCurrency = "EUR";
const style = "currency";

const currencyFormatter = (value: number): string => {
  const userStore = useUserStore();
  const { agencyLang, currencyCode } = userStore;
  const lang = agencyLang || navigator.language || fallbackLang;
  const currency = currencyCode || fallbackCurrency;
  return new Intl.NumberFormat(lang, {
    style,
    currency,
  }).format(Number(value));
};

const currencyDividedValueFormatted = (value: number): string =>
  currencyFormatter(value / 100);

const currencyMultipleValueFormatted = (value: number): string =>
  currencyFormatter(value * 100);

export {
  currencyFormatter,
  currencyDividedValueFormatted,
  currencyMultipleValueFormatted,
};
