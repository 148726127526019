interface PaginateModel {
  current: number;
  size: number;
  last: number;
  total: number;
  cache: Array<number>;
  params: any;
}

interface PaginateResponseModel {
  current_page: number;
  per_page: number;
  last_page: number;
  total: number;
  cache: Array<number>;
  params: any;
}

interface SetCurrentPageModel {
  pagination: PaginateModel;
  page?: number;
  context: any;
  url: string;
  params: any;
}

interface InflencuerDataItem {
  agency_earnings: number;
  clicks: string | number;
  client_id: number;
  date: string;
  user_earnings: number;
  user_id: number;
  username: string;
}

export interface DashboardDate {
  agency_earnings: number;
  date: string;
  user_earnings: number;
}

export interface DateRangeModel {
  start: Date;
  end: Date;
}

interface DateRangeFormattedModel {
  start: string;
  end: string;
}

enum MonthShortcuts {
  Jan = "January",
  Feb = "February",
  Mar = "March",
  Apr = "April",
  May = "May",
  Jun = "June",
  Jul = "July",
  Aug = "August",
  Sep = "September",
  Oct = "October",
  Nov = "November",
  Dec = "December",
}

interface Influencer {
  id: string;
  username: string;
  email: string;
  phone: string;
  created_at: string | Date;
  updated_at: string | Date;
  last_active: string | Date;
  first_name: string;
  surname: string;
  vat_no: string;
  country: string;
  city: string;
  address: string;
  postcode: string;
  tier_pricing_type: string;
  revenue_tier_id: number;
  is_suspended: boolean;
  is_verified: boolean;
  social_media: Array<SocialMedia>;
  follower_count: number;
  avg_like_count: number;
  avg_comment_count: number;
  avg_stories_per_day: string | number;
  profile_pic_s3_url: string;
  has_full_data: true;
  performance_score: string | number;
  branding_score: string | number;
  engagement_rate: number;
}

interface SocialMedia {
  id: number;
  user_id: number;
  type: string;
  identifier: string;
  is_valid: number | boolean;
  created_at: string;
  updated_at: string;
  followers: string | number | null;
  remote_id: string | number;
}

interface Link {
  before: string;
  after: string;
  status: string;
  cpc: number;
  offer: {
    ids: number;
    to: string | Date | null;
    from: string | Date | null;
  };
  offer_title: string;
  store: string;
  storeId: number;
  type: string;
  currency: string;
  user_revenue_cpc: number;
  user_instagram_cpc: number;
  user_revenue_cpa: number;
  user_instagram_cpa: number;
  user_revenue_cpc_formated: string;
  user_instagram_cpc_formated: string;
}

interface LinkListItem {
  user_id: number;
  link_date: string;
  tag_id: number;
  id: number;
  link: string;
  original_url: string;
  store_id: number;
  mtpc_url: string;
  user: {
    id: number;
    is_suspended: boolean;
    is_verified: boolean;
  };
  tag_store: {
    id: number;
    feed_name: string;
    logo_url: string;
  };
  clicks_and_earnings_v2?: {
    tag_id: number;
    clicks: string;
    instagram_clicks: string;
    general_clicks: string;
    earned: string;
    cpc_instagram_earned: string;
    cpc_general_earned: string;
    pending: string;
    cpa_instagram_earned: string;
    cpa_general_earned: string;
  }[];
  total_earning: {
    clicks: number;
    earned: number;
    pending: number;
    earned_formated: string;
    pending_formated: string;
  };
}

export {
  PaginateModel,
  PaginateResponseModel,
  SetCurrentPageModel,
  InflencuerDataItem,
  DateRangeFormattedModel,
  MonthShortcuts,
  Influencer,
  SocialMedia,
  Link,
  LinkListItem,
};
