import { createApp } from "vue";
import App from "@/App.vue";
import router from "@/router";
import { createPinia } from "pinia";
import { svgSpritePlugin } from "vue-svg-sprite";
import "./styles/app.css";
import VueClickAway from "vue3-click-away";
import ElementPlus from "element-plus";
import "element-plus/dist/index.css";
const pinia = createPinia();
import axios from "axios";
import VueAxios from "vue-axios";

axios.defaults.baseURL = process.env.VUE_APP_BASE_URL;

const app = createApp(App);
app
  .use(router)
  .use(pinia)
  .use(svgSpritePlugin, {
    url: require("./assets/svg-sprite.svg"),
    class: "svg-icon",
  })
  .use(ElementPlus)
  .use(VueClickAway)
  .use(VueAxios, axios)
  .mount("#app");
