import { defineStore } from "pinia";
import axios from "axios";
import { getErrorMessage } from "@/utils/helpFunctions";

let user = localStorage.getItem("user");
user = user ? JSON.parse(user) : "";

export const useUserStore = defineStore("UserStore", {
  state: () => ({
    user: user as any,
    messageError: "",
    loading: false,
    isDatepickerOpened: false,
    selectedAgency: {
      id: 0,
      name: "",
      storegroup: {} as any,
      permissions: {} as any,
    },
  }),

  getters: {
    isLogged: (state) => Boolean(state.user),
    currencyCode: (state) =>
      state.selectedAgency?.storegroup?.currency || "EUR",
    agencyLang: (state) => state.user?.client?.storegroup?.lang || "en",
    username: (state) => state.user?.username || "Username",
    clientName: (state) => state.selectedAgency?.name,
    currentAgency: (state) => state.selectedAgency,
  },
  actions: {
    async loginUser(data: { email: string; password: string }) {
      this.loading = true;
      const { email, password } = data;
      try {
        const { data } = await axios.post("agencies/login", {
          email,
          password,
        });
        this.user = data;
        localStorage.setItem("user", JSON.stringify(data));
      } catch (e: any) {
        this.messageError = getErrorMessage(e, "User not found");
        console.error("Couldn't fetch login ", e);
      } finally {
        this.loading = false;
      }
    },
    async resetPassword(data: { email: string }) {
      this.loading = true;
      const { email } = data;
      try {
        return await axios.post("agency/send-password-reset-email", {
          email,
        });
      } catch (e: any) {
        this.messageError = getErrorMessage(e, "Couldn't fetch reset password");
        console.error("Couldn't fetch reset password ", e);
      } finally {
        this.loading = false;
      }
    },
    async fetchAgencyUserPermissions() {
      try {
        const { data } = await axios.get(
          `agency/user/permissions/${this.selectedAgency.id}`,
          {
            headers: {
              Authorization: this.user.access_token,
            },
          }
        );
        this.selectedAgency.permissions = data;
      } catch (error) {
        console.error(error);
      }
    },
    async fetchEmilByCode(code: string) {
      this.loading = true;
      try {
        const { data } = await axios.get(`agency/invited-user-email/${code}`);
        return data;
      } catch (e: any) {
        this.messageError = getErrorMessage(e, "Couldn't fetch user email");
        console.error(e);
      } finally {
        this.loading = false;
      }
    },
    async register(
      data: {
        email: string;
        username: null | string;
        password: string;
      },
      code: string | null
    ) {
      this.loading = true;
      const { email, username, password } = data;
      try {
        const { data } = await axios.post("agency/register", {
          email,
          username,
          password,
          code,
        });
        this.user = data;
        localStorage.setItem("user", JSON.stringify(data));
      } catch (e: any) {
        this.messageError = getErrorMessage(e, "Register error");
        console.error(e);
      } finally {
        this.loading = false;
      }
    },
    async setSelectedAgency(id: number | string) {
      this.selectedAgency = this.user.clients.find(
        (client: any) => client.id == id
      );
      await this.fetchAgencyUserPermissions();
    },
    hasPermission(permission: string) {
      const permissions = this.selectedAgency?.permissions;
      return !permissions?.hide?.includes(permission);
    },
  },
});
