import { defineStore } from "pinia";
import axios from "axios";
import { useUserStore } from "@/store/UserStore";
import {
  buildQuery,
  getDateRangeThisMonthFormatted,
  getPrevPeriodFormatted,
} from "@/utils/helpers";
import { DashboardDate } from "@/utils/types";
import { getPreviousPeriod } from "@/utils/helpers";

const getDefaultDashboardData = () => ({
  activeUsers: 0,
  nrOfPosts: 0,
  totalAgencyEarnings: 0,
  totalUserEarnings: 0,
  totalClicks: 0,
  userAvgEarnings: 0,
  loading: false,
});

export const useDashboardStore = defineStore("DashboardStore", {
  state: () => ({
    chartData: {},
    dateRange: getDateRangeThisMonthFormatted(),
    prevPeriodFormatted: getPrevPeriodFormatted(null, "thisMonth"),
    data: getDefaultDashboardData(),
    dataPrevPeriod: getDefaultDashboardData(),
    shortcut: "thisMonth",
  }),

  getters: {
    isLoading: (state) => state.data.loading || state.dataPrevPeriod.loading,
  },

  actions: {
    async fetchDashboard() {
      this.data = getDefaultDashboardData();
      const userStore = useUserStore();
      const { access_token } = userStore.user;
      if (userStore.user.clients?.length === 1) {
        await userStore.setSelectedAgency(userStore.user.clients[0].id);
      }
      const agencyId = userStore.selectedAgency.id;
      const dashboardState = useDashboardStore();

      await dashboardState.fetchDashboardPrevPeriod(agencyId, access_token);
      this.data.loading = true;
      const { start, end } = this.dateRange;
      const queryString = buildQuery({
        from: start,
        to: end,
      });
      const url = `agency/dashboard/${agencyId}${queryString}`;
      try {
        const { data } = await axios.get(url, {
          headers: {
            Authorization: access_token,
          },
        });
        setDashboardData(this.data, data);
        this.chartData = {
          labels: data.dates.map((item: DashboardDate) => item.date),
          datasets: [
            {
              label: "User earnings",
              data: data.dates.map(
                (item: DashboardDate) => item.user_earnings / 100
              ),
              borderWidth: 1,
              pointBorderColor: "#ffffff",
              fill: true,
              borderColor: "#FF59AD",
              pointBackgroundColor: "#FF59AD",
              backgroundColor: (ctx: any) => {
                const canvas = ctx.chart.ctx;
                const gradient = canvas.createLinearGradient(0, 0, 100, 450);
                gradient.addColorStop(0, "rgba(255, 89, 173, 1)");
                gradient.addColorStop(0.5, "rgba(255, 89, 173, 0.5)");
                gradient.addColorStop(1, "rgba(255, 89, 173, 0.3)");
                return gradient;
              },
            },
          ],
        };
      } catch (e) {
        this.data = getDefaultDashboardData();
        console.error("Couldn't fetch dashboard ", e);
      } finally {
        this.data.loading = false;
      }
    },

    async fetchDashboardPrevPeriod(agencyId: number, access_token: string) {
      this.dataPrevPeriod = getDefaultDashboardData();
      this.dataPrevPeriod.loading = true;
      const { shortcut, dateRange } = this;
      const { start, end } = getPreviousPeriod({ shortcut, dateRange });
      this.prevPeriodFormatted = getPrevPeriodFormatted(dateRange, shortcut);
      const queryString = buildQuery({
        from: start,
        to: end,
      });
      const url = `agency/dashboard/${agencyId}${queryString}`;
      try {
        const { data } = await axios.get(url, {
          headers: {
            Authorization: access_token,
          },
        });
        setDashboardData(this.dataPrevPeriod, data);
      } catch (e) {
        this.dataPrevPeriod = getDefaultDashboardData();
        console.error("Couldn't fetch dashboard ", e);
      } finally {
        this.dataPrevPeriod.loading = false;
      }
    },
  },
});

const setDashboardData = (state: any, data: any) => {
  const {
    active_users = 0,
    nr_of_posts = 0,
    total_agency_earnings = 0,
    total_user_earnings = 0,
    total_clicks = 0,
    avg_user_earnings = 0,
  } = data;
  state.activeUsers = active_users;
  state.userAvgEarnings = avg_user_earnings;
  state.totalClicks = total_clicks;
  state.nrOfPosts = nr_of_posts;
  state.totalAgencyEarnings = total_agency_earnings;
  state.totalUserEarnings = total_user_earnings;
};
